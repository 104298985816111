<template>
  <div>
    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationComments.Main_Title')
      }}</template>
    </ui-section-header>

    <modal-create-comment
      v-if="showModalCreateComment"
      :showModal="showModalCreateComment"
      :onClickCancel="hideModal"
    />

    <div class="has-margin-bottom-x2">
      <Message
        :message="$t('Components.Reservation.ReservationComments.Info_text')"
      />
    </div>

    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a id="id_button_add" @click="setShowModalCreateComment">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>{{
              $t(
                'Components.Reservation.ReservationComments.Button_CreateComment'
              )
            }}</span>
          </a>
        </div>
      </div>
    </div>

    <CommentItem
      v-for="comment in filterComments(reservation.Comments, 0)"
      :key="comment.Id"
      :commentObj="comment"
      :comments="reservation.Comments"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import EventBus from '@/eventbus/event-bus'
import commentProvider from '@/providers/comment'

import Message from '@/components/UI/Message'
const CommentItem = () => import('@/components/Comments/CommentItem')
const ModalCreateComment = () =>
  import('@/components/Reservations/ModalAddComment')

export default {
  components: {
    CommentItem,
    Message,
    'modal-create-comment': ModalCreateComment,
  },

  props: {
    comments: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  data() {
    return {
      mComments: this.comments,
      showModalCreateComment: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    let self = this

    /**
     * Reservation hub listeners
     */
    // Comment added
    // this.$reservationHub.connection.on(
    //   this.$reservationHub.listeners.commentAdded,
    //   (response) => {
    //     self.reservation.Comments.push(JSON.parse(response))
    //     self.updateReservationStore()
    //   }
    // )

    // Reservation comment deleted
    this.$reservationHub.connection.on(
      this.$reservationHub.listeners.commentDeleted,
      (response) => {
        let comment = JSON.parse(response)
        if (comment.TypeId === 3 && comment.ItemId === self.reservation.Id) {
          let commentIndex = self.reservation.Comments.findIndex(
            (c) => c.Id === comment.Id
          )
          if (commentIndex > -1) {
            self.reservation.Comments.splice(commentIndex, 1)
          }
          self.updateReservationStore()
        }
      }
    )
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    filterComments(comments, parentId) {
      return comments.filter((c) => c.ParentId === parentId)
    },

    updateReservationStore() {
      this.setReservation(this.reservation)
    },

    setShowModalCreateComment() {
      this.showModalCreateComment = true
    },

    hideModal() {
      this.showModalCreateComment = false
    },
  },
}
</script>
