import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getcomments(page, itemsPerPage, typeId = 0) {
      let url = '?page=' + page + '&itemsPerPage=' + itemsPerPage

      if (typeId > 0) {
        url = url + '&typeId=' + typeId
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/comments` + url,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationComments(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/comment/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteComment(commentId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/comment/${commentId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async addComment(type, itemId, comment) {
      let criteria = {
        ItemId: itemId,
        Comment: comment
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/comment/${type}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async replyToComment(commentId, comment) {
      let criteria = {
        ItemId: commentId,
        Comment: comment
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/comment/reply`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
